<template>
  <div class="home">
    <onlyHead
      :superiorityId="superiorityId"
      :adFromId="adFromId"
      :partnerId="partnerId"
      :footId="footId"
      :widthNumber="widthNumber"
    ></onlyHead>
    <div class="banner"><img src="../assets/banner.png" alt="" /></div>
    <onlySuperiority
      id="superiority"
      :superiorityId="superiorityId"
      :number="number"
      :widthNumber="widthNumber"
    ></onlySuperiority>
    <onlyAdFrom id="adFrom"></onlyAdFrom>
    <onlyFlow id="Flow"></onlyFlow>
    <onlyPartner
      id="partner"
      :scrollTopNumber="scrollTopNumber"
      :partnerId="partnerId"
      :widthNumber="widthNumber"
    ></onlyPartner>
    <onlyFoot id="foot"></onlyFoot>
  </div>
</template>

<script>
// @ is an alias to /src
import onlyHead from '@/components/onlyHead'
import onlySuperiority from '@/components/onlySuperiority'
import onlyAdFrom from '@/components/onlyAdFrom'
import onlyFlow from '@/components/onlyFlow'
import onlyPartner from '@/components/onlyPartner'
import onlyFoot from '@/components/onlyFoot'

export default {
  name: 'HomeView',
  components: {
    onlyHead,
    onlySuperiority,
    onlyAdFrom,
    onlyFlow,
    onlyPartner,
    onlyFoot
  },
  data() {
    return {
      number: 0,
      scrollTopNumber: 0,
      heightNumber: 0,
      widthNumber: window.innerWidth || document.documentElement.clientWidth,
      superiorityId: 0,
      adFromId: 0,
      flowId: 0,
      partnerId: 0,
      footId: 0,
      superiorityHeight: 0,
      adFromHeight: 0,
      flowHeight: 0,
      partnerHeight: 0,
      footHeight: 0,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.windowScroll) //监听页面滚动
    window.onresize = () => {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth
      this.widthNumber = clientWidth
      // console.log('父组件data宽度在改变',this.widthNumber);
      // console.log('屏幕宽度在改变', clientWidth)
      if (clientWidth <= 768) {
        document.documentElement.style.fontSize = clientWidth / 7.5 + "px"
      } else {
        document.documentElement.style.fontSize = 16 + "px"
      }
    }
    this.getwidth()

  },
  methods: {
    // 监听页面滚动 获取滚动条位置
    windowScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.number = scrollTop
      this.scrollTopNumber = scrollTop
      // console.log('滚动到哪个位置', scrollTop)
    },
    // 获取五个子组件离顶部的距离
    goAnchor() {
      let superiorityId = document.getElementById('superiority')
      let adFromId = document.getElementById('adFrom')
      // let flowId = document.getElementById('flow')
      let partnerId = document.getElementById('partner')
      // 将五个子组件距离顶部的距离传递给head
      this.superiorityId = superiorityId.offsetTop
      this.adFromId = adFromId.offsetTop
      this.partnerId = partnerId.offsetTop
      // 元素高度
      this.superiorityHeight = superiorityId.clientHeight
      // this.adFromHeight = adFromId.clientHeight
      // this.flowHeight = flowId.clientHeight
      // this.partnerHeight = partnerId.clientHeight
      // this.footHeight = footId.clientHeight
      // console.log('广告形式距离顶部位置', adFromId.offsetTop)
      // console.log('合作伙伴距离顶部位置',partnerId.offsetTop);
      // console.log('元素高度', this.adFromHeight)
      // console.log('元素高度', this.flowHeight)
      // console.log('元素高度', this.partnerHeight)
      // console.log('元素高度', this.footHeight)
      // 将页面的高度获取到
      this.footId = document.documentElement.offsetHeight
    },
    getwidth() {
      const clientWidth = window.innerWidth || document.documentElement.clientWidth
      this.widthNumber = clientWidth
      // console.log('父组件data宽度在改变',this.widthNumber);
      // console.log('屏幕宽度在改变', clientWidth)
      if (clientWidth <= 768) {
        document.documentElement.style.fontSize = clientWidth / 7.5 + "px"
      } else {
        document.documentElement.style.fontSize = 16 + "px"
      }

    }
  },

  destroyed() {
    window.removeEventListener("scroll", this.windowScroll)//销毁滚动事件


  },
  created() {
    this.$nextTick(() => {
      this.goAnchor()
      //监听页面宽度变化
      this.getwidth()
    })
  }
}
</script>
<style lang="less">
.banner {
  width: 100%;
  // max-width: 1920px;
  // height: 700px;
  height: auto;
  // max-height: 700px;
  position: relative;
  margin-top: 0.8rem;
  img {
    width: 100%;
    height: auto;
  }
}
// @media screen and (max-width: 768px) {
//   .banner{
//     // height: 7rem;
//     height: auto;
//   }
// }
</style>
