<template>
  <div class="conten">
    <h1>期待您的合作</h1>
    <div class="text">
      <div
        class="desc"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <p>黄经理</p>
        <p>电话: 13418185847</p>
        <p>邮箱: huangjialun@xiway.cn</p>
      </div>
      <div class="descImg">
        <img
          style="width: 100%; height: 100%"
          src="../assets/huang.png"
          alt=""
        />
      </div>
    </div>
    <div class="text">
      <div
        class="desc"
        style="
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        "
      >
        <p>董经理</p>
        <p>电话: 18924642119</p>
        <p>邮箱: dongkenan@xiway.cn</p>
      </div>
      <div class="descImg">
        <img
          style="width: 100%; height: 100%"
          src="../assets/dong.png"
          alt=""
        />
      </div>
    </div>
    <div class="broder" style="background-color: #22252c"></div>
    <p class="Publish" style="color: #a1a2a5">
      <a
        href="http://beian.miit.gov.cn/"
        target="_blank"
        style="text-decoration: none; color: inherit"
        >粤ICP备2023100201号-1 版权所有：深圳市斗鲸网络科技有限公司 ©2023
        Doujing</a
      >
    </p>
    <p class="Publish" style="color: #a1a2a5; margin: 0">
      公司地址：深圳市南山区软件园一期301-2
    </p>
  </div>
</template>

<script>
export default {
  name: 'onlyFoot',
  created() {
    // console.log('底部显示了');
  }
}
</script>

<style lang="less" scoped>
.conten {
  width: 100%;
  // max-width: 1920px;
  height: 400px;
  // max-height: 400px;
  background: #13161e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  h1 {
    color: #ffffff;
    margin-top: 20px;
  }
  p {
    color: #ffffff;
    font-weight: 500;
  }
  .text {
    width: 600px;
    // max-width: 600px;
    height: 100px;
    // max-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .desc {
      height: 100px;
    }
    .descImg {
      width: 100px;
      height: 100px;
    }
  }
  .broder {
    width: 68%;
    height: 1px;
    margin-top: 20px;
  }
  .Publish {
    font-size: 14px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .conten {
    height: auto;
    h1 {
      font-size: 0.4rem;
      margin-top: 0.2rem;
    }
    .text {
      width: 6rem;
      // max-width: 6rem;
      height: 1rem;
      // max-height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.2rem;
      .desc {
        height: 1rem;
        p {
          font-size: 0.15625rem;
        }
      }
      .descImg {
        width: 1rem;
        height: 1rem;
      }
    }
    .broder {
      width: 68%;
      height: 0.009765625rem;
      margin-top: 0.3rem;
    }
    .Publish {
      width: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.1rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }
  }
}
</style>