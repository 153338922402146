<template>
  <div class="conten" id="contair">
    <div class="title"><h1>我们的优势</h1></div>
    <div class="message" v-if="modo1">
      <!-- 用户数据全链条打通 -->
      <div class="map box" id="first">
        <div class="text" id="text_1">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">用户数据全链条打通</h2>
            <span class="title_2"
              >广告主第一方私有数据、合作第三方大数据（已涵盖7.5亿移动端用户数据）</span
            >
            <span class="title_2 desc"
              >八大维度，涵盖合作媒体用户自然属性及行为兴趣……</span
            >
          </div>
        </div>
        <div class="img" id="img1"><img src="../assets/sz.png" alt="" /></div>
      </div>
      <!-- 用户兴趣细粒度多重判定 -->
      <div class="map box">
        <div class="img" id="img2">
          <img src="../assets/user.png" alt="" />
        </div>
        <div class="text" id="text_2">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">用户兴趣细粒度多重判定</h2>
            <span class="title_2">DMP自定义人群包，灵活支持特色定向投放</span>
            <span class="title_2" style="margin-top: 20px"
              >数据拓展 Lookalike & 数据运算 交叉 | 合并 | 排除</span
            >
          </div>
        </div>
      </div>
      <!-- 广告数据实时追踪 -->
      <div class="map box">
        <div class="text" id="text_3">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">广告数据实时追踪</h2>
            <span class="title_2">广告投放基础数据（流量监测、效果追踪）</span>
            <span class="title_2" style="margin-top: 20px"
              >用户浏览、跳转和转化行为……</span
            >
          </div>
        </div>
        <div class="img" id="img3">
          <img src="../assets/data.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 身为移动端时使用这套代码 -->
    <div class="message" v-if="modo2">
      <!-- 用户数据全链条打通 -->
      <div class="map box" id="first1">
        <div class="text" id="text_1">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">用户数据全链条打通</h2>
            <span class="title_2"
              >广告主第一方私有数据、合作第三方大数据（已涵盖7.5亿移动端用户数据）</span
            >
            <span class="title_2 desc"
              >八大维度，涵盖合作媒体用户自然属性及行为兴趣……</span
            >
          </div>
        </div>
        <div class="img" id="img1"><img src="../assets/sz.png" alt="" /></div>
      </div>
      <!-- 用户兴趣细粒度多重判定 -->
      <div class="map box">
        <div class="text" id="text_2">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">用户兴趣细粒度多重判定</h2>
            <span class="title_2">DMP自定义人群包，灵活支持特色定向投放</span>
            <span class="title_2" style="margin-top: 20px"
              >数据拓展 Lookalike & 数据运算 交叉 | 合并 | 排除</span
            >
          </div>
        </div>
        <div class="img" id="img2">
          <img src="../assets/user.png" alt="" />
        </div>
      </div>
      <!-- 广告数据实时追踪 -->
      <div class="map box">
        <div class="text" id="text_3">
          <div
            style="position: absolute; top: 50%; transform: translate(0, -50%)"
          >
            <div class="border"></div>
            <h2 class="title_1">广告数据实时追踪</h2>
            <span class="title_2">广告投放基础数据（流量监测、效果追踪）</span>
            <span class="title_2" style="margin-top: 20px"
              >用户浏览、跳转和转化行为……</span
            >
          </div>
        </div>
        <div class="img" id="img3">
          <img src="../assets/data.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'onlySuperiority',
  props: {
    number: Number,
    widthNumber: Number,
    superiorityId: Number
  },
  data() {
    return {
      numbers: 0,
      modo1: true,
      modo2: false,
      firstNumber: 0
    }
  },
  computed: {
    dataChange() {
      const { number, widthNumber } = this
      return { number, widthNumber }
    }
  },
  watch: {
    dataChange: {
      handler(newValue, oldValue) {
        if (newValue.widthNumber <= 768) {
          this.modo1 = false
          this.modo2 = true
        } else if (newValue.widthNumber > 768) {
          this.modo2 = false
          this.modo1 = true
        }
      },
      deep: true,
      immediate: true
    },
    number(newVal, oldVal) {
      // console.log('superiorityId', this.superiorityId)
      this.numbers = newVal
      let text_1 = document.getElementById("text_1")
      let img_1 = document.getElementById("img1")
      let text_2 = document.getElementById("text_2")
      let img_2 = document.getElementById("img2")
      let text_3 = document.getElementById("text_3")
      let img_3 = document.getElementById("img3")
      // 判断用户滑动到了哪里 从而执行动画
      if (this.numbers >= this.superiorityId && this.widthNumber > 768) {
        this.animationExecute(text_1)
        this.animationExecute(img_1)
      }
      else {
        text_1.style.animationPlayState = 'paused'
        img_1.style.animationPlayState = 'paused'
      }
      if (this.numbers >= (this.superiorityId + this.firstNumber) && this.widthNumber > 768) {
        this.animationExecute(text_2)
        this.animationExecute(img_2)
      }
      else {
        text_2.style.animationPlayState = 'paused'
        img_2.style.animationPlayState = 'paused'
      }
      if (this.numbers >= (this.superiorityId + this.firstNumber + this.firstNumber) && this.widthNumber > 768) {
        this.animationExecute(text_3)
        this.animationExecute(img_3)
      }
      else {
        text_3.style.animationPlayState = 'paused'
        img_3.style.animationPlayState = 'paused'
      }
    },
  },
  mounted() {
    this.getHeight()
  },
  created() {
    // console.log('我显示了');
  },
  methods: {
    animationExecute(name) {
      if (name) {
        name.style.animationDuration = '1.5s'
        name.style.animationTimingFunction = 'ease-out'
        name.style.animationIterationCount = 1
        name.style.animationPlayState = 'running'
      }
    },
    // 获取元素的高度
    getHeight() {
      let first = document.getElementById("first")
      let first1 = document.getElementById("first1")
      if (this.modo1) {
        this.firstNumber = first.clientHeight
      } else if (this.modo2) {
        this.firstNumber = first1.clientHeight
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 字段动画
@keyframes test {
  0% {
    opacity: 0;
    transform: translateX(-86vh);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes imgs {
  0% {
    opacity: 0;
    transform: translateX(86vh);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.conten {
  width: 100%;
  // max-width: 1920px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // h1 {
  //   font-size: 42px;
  // }
  //   我们的优势
  .title {
    margin: 70px 0px 70px 0px;
  }
  .message {
    width: 68%;
    // width: 1300px;
    // max-width: 1300px;
    height: 1400px;
    // max-height: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .box {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: space-between;
      .text {
        width: 48%;
        // max-width: 600px;
        height: 100%;
        // max-height: 400px;
        display: flex;
        flex-direction: column;
        position: relative;
        animation-name: test;
        .border {
          width: 70px;
          height: 5px;
          background: linear-gradient(to right, #486efd, #4b9bfc);
          border-radius: 5px;
          margin-bottom: 20px;
        }
        .title_1 {
          font-size: 28px;
        }
        .title_2 {
          font-size: 18px;
          color: #898989;
          display: block;
          margin-top: 30px;
        }
        .desc {
          margin-top: 20px;
        }
        .title_02 {
          margin-top: 60px;
        }
      }
      #img2 {
        animation-name: test;
      }
      .img {
        width: 48%;
        // max-width: 600px;
        height: 100%;
        // max-height: 400px;
        position: relative;
        border-radius: 10px;
        animation-name: imgs;
        // animation: imgs 1.5s 1;
        // animation-timing-function: ease-out;
        // animation-play-state: paused;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      #text_2 {
        animation-name: imgs;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .conten {
    // width: 100%;
    overflow: hidden;
    height: auto;
    .title {
      margin: 0.1rem 0 0.5rem 0;
      h1 {
        font-size: 0.4rem;
      }
    }
    .message {
      width: 90%;
      // margin-top: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;
      .box {
        width: 100%;
        // height: 6rem;
        height: auto;
        display: flex;
        flex-direction: column;
        .text {
          width: 100%;
          height: 3rem;
          .border {
            width: 0.7rem;
            height: 0.048828125rem;
            margin-bottom: 0.2rem;
          }
          .title_1 {
            font-size: 0.2734375rem;
          }
          .title_2 {
            font-size: 0.17578125rem;
            margin-top: 0.2rem;
          }
          .desc {
            margin-top: 0.2rem;
          }
        }
        .img {
          width: 100%;
          height: 3.5rem;
          margin-top: 0.1rem;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>