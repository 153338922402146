var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"title",attrs:{"id":"content"}},[_c('div',{staticClass:"titleBox",on:{"click":_vm.goTop}},[_c('img',{staticClass:"titleImg",attrs:{"src":require("../assets/logoS.png"),"alt":""}})]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"button",attrs:{"type":"primary"},on:{"click":_vm.checkBoxShow}},[_c('i',{staticClass:"el-icon-menu"})]),(_vm.conceal)?_c('div',{staticClass:"headBox"},[_c('div',{staticClass:"text",on:{"click":_vm.goTop}},[_vm._v("首页")]),_c('div',{staticClass:"text",on:{"click":_vm.goSuperiority}},[_vm._v("我们的优势")]),_c('div',{staticClass:"text",on:{"click":_vm.goAdFrom}},[_vm._v("广告形式")]),_vm._m(0),_c('div',{staticClass:"text",on:{"click":_vm.goPartner}},[_vm._v("合作伙伴")]),_c('div',{staticClass:"text",on:{"click":_vm.goFoot}},[_vm._v("联系我们")])]):_vm._e(),(!_vm.conceal)?_c('div',{class:`headBox ${
        _vm.inAnimation === 'second'
          ? 'anim'
          : _vm.inAnimation === 'thirdly'
          ? 'animNone'
          : ''
      } `},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goTop}},[_vm._v("首页")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goSuperiority}},[_vm._v(" 我们的优势 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goAdFrom}},[_vm._v("广告形式")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goMiede}},[_c('a',{staticStyle:{"text-decoration":"none","color":"#000000"},attrs:{"href":"http://ssp.ixiway.com/#/login","target":"_blank"}},[_vm._v("媒体入口")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goPartner}},[_vm._v("合作伙伴")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.conceal),expression:"!conceal"}],staticClass:"text",on:{"click":_vm.goFoot}},[_vm._v("联系我们")])]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('a',{staticStyle:{"text-decoration":"none","color":"#000000"},attrs:{"href":"http://ssp.ixiway.com/#/login","target":"_blank"}},[_vm._v("媒体入口")])])
}]

export { render, staticRenderFns }