<template>
  <div class="conten">
    <h1>优质流量 合作共赢</h1>
    <div class="conten_box">
      <div class="imgs"><img src="../assets/flow.png" alt="" /></div>
      <div class="right_box">
        <div class="desc">
          <img src="../assets/media.png" alt="" />
          <p>独家授权 众多媒体</p>
        </div>
        <div class="desc">
          <img src="../assets/flux.png" alt="" />
          <p>100%流量资源直签</p>
        </div>
        <div class="desc">
          <img src="../assets/exposure.png" alt="" />
          <p>日均曝光1.5亿+次</p>
        </div>
        <div class="desc">
          <img src="../assets/manage.png" alt="" />
          <p>一站式管理 上千广告位</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onlyFlow'
}
</script>

<style lang="less" scoped>
.conten {
  width: 100%;
  // max-width: 1920px;
  height: 530px;
  //   max-height: 530px;
  // background: grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .conten_box {
    width: 62%;
    // width: 1200px;
    // max-width: 1200px;
    height: 300px;
    // max-height: 300px;
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
  }
  .right_box {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // align-items: center;
    // margin: 0 auto;
    .desc {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 26%;
      img {
        width: 30px;
        height: 30px;
      }
      p {
        font-weight: 500;
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .conten {
    width: 100%;
    height: auto;
    // max-height: 5.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 0.4rem;
    }
    .conten_box {
      width: 80%;
      // max-width: 80%;
      height: auto;
      display: flex;
      flex-direction: column;
      margin-top: 0.8rem;
      justify-content: space-between;
      align-items: center;
      .imgs {
        width: 100%;
        height: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right_box {
        width: 100%;
        justify-content: center;
        align-items: center;
        .desc {
            margin-left: 50%;
            margin-top: 0.1rem;
          img {
            width: 0.3rem;
            height: 0.3rem;
          }
          p {
            font-weight: 500;
            font-size: 0.2rem;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}
</style>