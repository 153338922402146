<template>
  <div class="head">
    <div class="title" id="content">
      <!-- <div class="titleBox" @click="goTop">
        <img class="titleImg" src="../assets/jm.png" alt="" />
        <h2>鲸美互联</h2>
      </div> -->

      <div class="titleBox" @click="goTop">
        <img class="titleImg" src="../assets/logoS.png" alt="" />
      </div>
      <el-button
        v-show="!conceal"
        class="button"
        type="primary"
        @click="checkBoxShow"
        ><i class="el-icon-menu"></i
      ></el-button>
      <div class="headBox" v-if="conceal">
        <div class="text" @click="goTop">首页</div>
        <div class="text" @click="goSuperiority">我们的优势</div>
        <div class="text" @click="goAdFrom">广告形式</div>
        <div class="text">
          <a
            href="http://ssp.ixiway.com/#/login"
            target="_blank"
            style="text-decoration: none; color: #000000"
            >媒体入口</a
          >
        </div>
        <div class="text" @click="goPartner">合作伙伴</div>
        <div class="text" @click="goFoot">联系我们</div>
      </div>
      <div v-if="!conceal"
        :class="`headBox ${
          inAnimation === 'second'
            ? 'anim'
            : inAnimation === 'thirdly'
            ? 'animNone'
            : ''
        } `"
      >
        <div class="text" v-show="!conceal" @click="goTop">首页</div>
        <div class="text" v-show="!conceal" @click="goSuperiority">
          我们的优势
        </div>
        <div class="text" v-show="!conceal" @click="goAdFrom">广告形式</div>
        <div class="text" v-show="!conceal" @click="goMiede">
          <a
            href="http://ssp.ixiway.com/#/login"
            target="_blank"
            style="text-decoration: none; color: #000000"
            >媒体入口</a
          >
        </div>
        <div class="text" v-show="!conceal" @click="goPartner">合作伙伴</div>
        <div class="text" v-show="!conceal" @click="goFoot">联系我们</div>
      </div>
    </div>
  </div>
</template>
  
  <script>

export default {
  name: 'onlyHead',
  data() {
    return {
      conceal: false,
      inAnimation: 'first',
      windowWidth: document.documentElement.clientWidth  // 实时获取用户设备的宽度
    }
  },
  props: ['superiorityId', 'adFromId', 'flowId', 'partnerId', 'footId', 'widthNumber'],
  watch: {
    widthNumber(newVal, oldVal) {
      if (newVal > 768) {
        this.conceal = true
      } else if (newVal < 768) {
        this.conceal = false
      }
    }
  },
  methods: {
    // 回到顶部
    goTop() {
      console.log('点击了回顶')
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.inAnimation = 'thirdly'
    },
    // 去我们的优势
    goSuperiority() {
      // window.scrollTo({ top: 700, behavior: 'smooth' })
      window.scrollTo({ top: this.superiorityId, behavior: 'smooth' })
      this.inAnimation = 'thirdly'
    },
    // 去广告形式
    goAdFrom() {
      console.log('去广告形式', this.adFromId)
      // window.scrollTo({ top: 2300, behavior: 'smooth' })
      window.scrollTo({ top: this.adFromId, behavior: 'smooth' })
      this.inAnimation = 'thirdly'
    },
    // 去合作伙伴
    goPartner() {
      // window.scrollTo({ top: 3589, behavior: 'smooth' })
      window.scrollTo({ top: this.partnerId, behavior: 'smooth' })
      this.inAnimation = 'thirdly'
    },
    // 去底部
    goFoot() {
      // window.scrollTo({ top: 3789, behavior: 'smooth' })
      window.scrollTo({ top: this.footId, behavior: 'smooth' })
      this.inAnimation = 'thirdly'
    },
    goMiede() {
      this.inAnimation = 'thirdly'
    },
    // 点击展开下拉选择器
    checkBoxShow() {
      if (this.inAnimation === 'first' || this.inAnimation === 'thirdly') {
        this.inAnimation = 'second'
      } else if (this.inAnimation === 'first' || this.inAnimation === 'second') {
        this.inAnimation = 'thirdly'
      }
    }
  },
  mounted() {
    // 页面一加载根据页面来判断是移动还是pc端
    if (this.windowWidth > 768) {
      this.conceal = true
    } else if (this.windowWidth < 768) {
      this.conceal = false
    }
  }
}
  </script>
  
  <style lang="less" scoped>
.head {
  width: 100%;
  // max-width: 1920px;
  height: 80px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .title {
    width: 68%;
    // width: 1300px;
    // max-width: 1300px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // position: relative;
  }
  .headBox {
    // position: absolute;
    // top: 0;
    // right: 0;

    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: skyblue;
    z-index: 9999;
    .text {
      font-weight: 900;
      cursor: pointer;
    }
  }
  .titleBox {
    width: 110px;
    height: 110px;
    // display: flex;
    // position: absolute;
    // justify-content: center;
    // align-content: center;
    cursor: pointer;
    // h2 {
    //   text-align: center;
    //   line-height: 50px;
    //   margin-left: 10px;
    // }
    .titleImg {
      // width: 50px;
      // height: 50px;
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .head {
    width: 100%;
    height: 0.8rem;
    position: fixed;
    top: 0;
    left: 0;
    .title {
      width: 13rem;
      // max-width: 13rem;
    }
    .titleBox {
      width: 1.1rem;
      height: 1.1rem;
      margin-left: 0.2rem;
      // position: absolute;
      // justify-content: center;
      // align-content: center;
      // h2 {
      //   font-size: 0.3rem;
      //   text-align: center;
      //   line-height: 0.5rem;
      //   margin-left: 0.1rem;
      // }
      .titleImg {
        // width: 0.5rem;
        // height: 0.5rem;
        width: 100%;
        height: 100%;
      }
    }
    .headBox {
      width: 100%;
      // height: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      position: absolute;
      top: 0.8rem;
      background: #ffffff;
      height: 0;
      overflow: hidden;
      .text {
        font-size: 0.28rem;
        padding-left: 0.2rem;
      }
    }
    .button {
      width: 0.5rem;
      height: 0.4rem;
      background-color: linear-gradient(to right, #486efd, #4b9bfc);
      border-color: linear-gradient(to right, #486efd, #4b9bfc);
      padding: 0px 0px;
      position: absolute;
      right: 0.2rem;
    }
    .el-icon-menu {
      font-size: 0.2rem;
      line-height: 1.5;
    }
  }
}
.anim {
  animation: choiceBoxShow 0.5s linear;
  animation-fill-mode: forwards;
}

@keyframes choiceBoxShow {
  0% {
    height: 0rem;
  }

  100% {
    height: 2.5rem;
  }
}

.animNone {
  animation: choiceBoxHide 0.5s linear;
  animation-fill-mode: forwards;
}

@keyframes choiceBoxHide {
  0% {
    height: 2.5rem;
  }

  100% {
    height: 0ren;
  }
}
</style>