var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"conten",attrs:{"id":"contair"}},[_vm._m(0),(_vm.modo1)?_c('div',{staticClass:"message"},[_vm._m(1),_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.modo2)?_c('div',{staticClass:"message"},[_vm._m(4),_vm._m(5),_vm._m(6)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("我们的优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box",attrs:{"id":"first"}},[_c('div',{staticClass:"text",attrs:{"id":"text_1"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("用户数据全链条打通")]),_c('span',{staticClass:"title_2"},[_vm._v("广告主第一方私有数据、合作第三方大数据（已涵盖7.5亿移动端用户数据）")]),_c('span',{staticClass:"title_2 desc"},[_vm._v("八大维度，涵盖合作媒体用户自然属性及行为兴趣……")])])]),_c('div',{staticClass:"img",attrs:{"id":"img1"}},[_c('img',{attrs:{"src":require("../assets/sz.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box"},[_c('div',{staticClass:"img",attrs:{"id":"img2"}},[_c('img',{attrs:{"src":require("../assets/user.png"),"alt":""}})]),_c('div',{staticClass:"text",attrs:{"id":"text_2"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("用户兴趣细粒度多重判定")]),_c('span',{staticClass:"title_2"},[_vm._v("DMP自定义人群包，灵活支持特色定向投放")]),_c('span',{staticClass:"title_2",staticStyle:{"margin-top":"20px"}},[_vm._v("数据拓展 Lookalike & 数据运算 交叉 | 合并 | 排除")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box"},[_c('div',{staticClass:"text",attrs:{"id":"text_3"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("广告数据实时追踪")]),_c('span',{staticClass:"title_2"},[_vm._v("广告投放基础数据（流量监测、效果追踪）")]),_c('span',{staticClass:"title_2",staticStyle:{"margin-top":"20px"}},[_vm._v("用户浏览、跳转和转化行为……")])])]),_c('div',{staticClass:"img",attrs:{"id":"img3"}},[_c('img',{attrs:{"src":require("../assets/data.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box",attrs:{"id":"first1"}},[_c('div',{staticClass:"text",attrs:{"id":"text_1"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("用户数据全链条打通")]),_c('span',{staticClass:"title_2"},[_vm._v("广告主第一方私有数据、合作第三方大数据（已涵盖7.5亿移动端用户数据）")]),_c('span',{staticClass:"title_2 desc"},[_vm._v("八大维度，涵盖合作媒体用户自然属性及行为兴趣……")])])]),_c('div',{staticClass:"img",attrs:{"id":"img1"}},[_c('img',{attrs:{"src":require("../assets/sz.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box"},[_c('div',{staticClass:"text",attrs:{"id":"text_2"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("用户兴趣细粒度多重判定")]),_c('span',{staticClass:"title_2"},[_vm._v("DMP自定义人群包，灵活支持特色定向投放")]),_c('span',{staticClass:"title_2",staticStyle:{"margin-top":"20px"}},[_vm._v("数据拓展 Lookalike & 数据运算 交叉 | 合并 | 排除")])])]),_c('div',{staticClass:"img",attrs:{"id":"img2"}},[_c('img',{attrs:{"src":require("../assets/user.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map box"},[_c('div',{staticClass:"text",attrs:{"id":"text_3"}},[_c('div',{staticStyle:{"position":"absolute","top":"50%","transform":"translate(0, -50%)"}},[_c('div',{staticClass:"border"}),_c('h2',{staticClass:"title_1"},[_vm._v("广告数据实时追踪")]),_c('span',{staticClass:"title_2"},[_vm._v("广告投放基础数据（流量监测、效果追踪）")]),_c('span',{staticClass:"title_2",staticStyle:{"margin-top":"20px"}},[_vm._v("用户浏览、跳转和转化行为……")])])]),_c('div',{staticClass:"img",attrs:{"id":"img3"}},[_c('img',{attrs:{"src":require("../assets/data.png"),"alt":""}})])])
}]

export { render, staticRenderFns }