<template>
  <div class="conten">
    <h1>广告形式</h1>
    <div class="adFrom">
      <div class="show-box2">
        <div
          class="show"
          :class="{ show_bg: seen1 }"
          @mouseenter="enter1"
          @mouseleave="leave1"
          @click="first1"
        >
          <p class="title" :class="{ 'title-color': seen1 }">开屏广告</p>
          <p class="showAdApp" :class="{ 'title-color': seen1 }">
            简单来说开屏广告就是app第一次启动打开时出现的广告画面，我们称这种广告为开屏广告。
          </p>
        </div>
        <div
          class="show"
          :class="{ show_bg: seen2 }"
          @mouseenter="enter2"
          @mouseleave="leave2"
          @click="first2"
        >
          <div class="title" :class="{ 'title-color': seen2 }">原生广告</div>
          <div class="showAdApp" :class="{ 'title-color': seen2 }">
            原生广告通过“和谐”的内容呈现品牌信息，不破坏用户的体验，为用户提供有价值的信息。让用户自然地接受信息。
          </div>
        </div>
        <div
          class="show"
          :class="{ show_bg: seen3 }"
          @mouseenter="enter3"
          @mouseleave="leave3"
          @click="first3"
        >
          <div class="title" :class="{ 'title-color': seen3 }">横幅广告</div>
          <div class="showAdApp" :class="{ 'title-color': seen3 }">
            广告横幅标语 1. 用第三只眼睛看世界。 ——出自:海鸥牌相机广告 2.
            安全保障 自有一套 ——出自:安全套 3. 可能是世界上最好的啤酒
          </div>
        </div>
        <div
          class="show"
          :class="{ show_bg: seen4 }"
          @mouseenter="enter4"
          @mouseleave="leave4"
          @click="first4"
        >
          <div class="title" :class="{ 'title-color': seen4 }">插屏广告</div>
          <div class="showAdApp" :class="{ 'title-color': seen4 }">
            “插屏插屏，是移动广告的一种常见形式，具有强烈的视觉冲击效果，是移动广告平台主流的广告形式之一。
          </div>
        </div>
      </div>
      <div class="phone">
        <img class="image" v-show="seen1" src="../assets/kaiping.jpg" />
        <img class="image" v-show="seen2" src="../assets/yuans.jpg" />
        <img class="image" v-show="seen3" src="../assets/henfu.jpg" />
        <img class="image" v-show="seen4" src="../assets/chaping.jpg" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'onlyAdFrom',
  data() {
    return {
      seen1: true,
      seen2: false,
      seen3: false,
      seen4: false,
    }
  },
  methods: {
    // 开屏广告
    enter1(e) {
      this.seen2 = false
      this.seen3 = false
      this.seen4 = false
      this.seen1 = true
    },
    leave1(e) {
      if (!this.seen2 && !this.seen3 && !this.seen4) {
        this.seen1 = true
      } else {
        this.seen1 = false
      }
    },
    // 点击
    first1() {
      this.seen2 = false
      this.seen3 = false
      this.seen4 = false
      this.seen1 = true
    },
    // 原生广告
    enter2() {
      this.seen1 = false
      this.seen3 = false
      this.seen4 = false
      this.seen2 = true
    },
    leave2() {
      if (!this.seen1 && !this.seen3 && !this.seen4) {
        this.seen2 = true
      } else {
        this.seen2 = false
      }
    },
    first2() {
      this.seen1 = false
      this.seen3 = false
      this.seen4 = false
      this.seen2 = true
    },
    // 横幅广告
    enter3() {
      this.seen1 = false
      this.seen2 = false
      this.seen4 = false
      this.seen3 = true
    },
    leave3() {
      if (!this.seen2 && !this.seen1 && !this.seen4) {
        this.seen3 = true
      } else {
        this.seen3 = false
      }
    },
    first3() {
      this.seen1 = false
      this.seen2 = false
      this.seen4 = false
      this.seen3 = true
    },
    // 插屏广告
    enter4() {
      this.seen1 = false
      this.seen3 = false
      this.seen2 = false
      this.seen4 = true
    },
    leave4() {
      if (!this.seen2 && !this.seen1 && !this.seen3) {
        this.seen4 = true
      } else {
        this.seen4 = false
      }
    },
    first4() {
      this.seen1 = false
      this.seen3 = false
      this.seen2 = false
      this.seen4 = true
    }
  }
}

</script>

<style lang="less" scoped>
.conten {
  width: 100%;
  // max-width: 1920px;
  height: 680px;
  // max-height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  box-sizing: border-box;
  .adFrom {
    width: 62%;
    // width: 1200px;
    // max-width: 1200px;
    height: 680px;
    // max-height: 680px;
    // background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .phone {
    width: 230px;
    // max-width: 230px;
    height: 460px;
    // max-height: 460px;
    .image {
      width: 100%;
      height: 100%;
    }
  }
  .show-box2 {
    width: 75%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    .show {
      position: relative;
      width: 160px;
      height: 170px;
      border-radius: 15px;
      z-index: 10;
      padding: 40px 24px;
      box-shadow: 0 0 44px rgb(0 0 0 / 6%);
      cursor: pointer;
      .title {
        color: #333333;
        font-size: 25px;
        font-weight: 500;
        line-height: 30px;
        height: 30px;
        margin-bottom: 24px;
        font-family: PingFang SC;
      }
      .showAdApp {
        color: #666;
        font-size: 14px;
        line-height: 22px;
        width: 160px;
        height: 170px;
      }
    }
  }
  .show:hover {
    background: linear-gradient(to right, #486efd, #4b9bfc);
  }
  .show:hover .title {
    color: #fff;
  }

  .show:hover .showAdApp {
    color: #fff;
  }
  .title-color {
    color: #ffffff !important;
  }
  .show_bg {
    background: linear-gradient(to right, #486efd, #4b9bfc) !important;
  }
}
@media screen and (max-width: 768px) {
  .conten {
    width: 100%;
    // height: 7rem;
    height: auto;
    margin-top: 1rem;
    h1 {
      font-size: 0.4rem;
    }
    .adFrom {
      width: 90%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;
      .show-box2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        .show {
          width: 1.9rem;
          height: 2.1rem;
          border-radius: 0.146484375rem;
          z-index: 10;
          padding: 0.390625rem 0.234375rem;
          box-shadow: 0 0 0.4296875 rgb(0 0 0 / 6%);
          margin-top: 0.1rem;
          text-overflow: ellipsis;
          overflow: hidden;
          // direction: ltr;
          // overflow: auto;
          .title {
            font-size: 0.244140625rem;
            font-weight: 500;
            line-height: 0.29296875rem;
            height: auto;
            margin-bottom: 0;
          }
          .showAdApp {
            color: #666;
            font-size: 0.09765625rem;
            margin-top: 0.1rem;
            line-height: 0.3rem;
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
</style>