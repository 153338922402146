<template>
  <div class="conten">
    <h1 id="partner">合作伙伴</h1>
    <div class="imgList">
      <div class="imgList_01 img_01" id="imgS1">
        <div class="item item_1" v-for="item in imgList_1" :key="item.id">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <div class="imgList_01 img_02" id="imgS2">
        <div class="item item_2" v-for="item in imgList_2" :key="item.id">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <div class="imgList_01 img_03" id="imgS3">
        <div class="item item_3" v-for="item in imgList_3" :key="item.id">
          <img :src="item.img" alt="" />
        </div>
      </div>
      <div class="imgList_01 img_04" id="imgS4">
        <div class="item item_4" v-for="item in imgList_4" :key="item.id">
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'onlyPartner',
  props: {
    scrollTopNumber: Number,
    partnerId: Number,
    widthNumber: Number
  },
  data() {
    return {
      imgList_1: [
        { img: '/logo/aiqiyi.jpg', id: 1 },
        { img: '/logo/baidu.png', id: 2 },
        { img: '/logo/baobaoshu.webp', id: 3 },
        { img: '/logo/dangdang.webp', id: 4 },
        { img: '/logo/dashao.png', id: 5 },
        { img: '/logo/didi.webp', id: 6 },
        { img: '/logo/doov.webp', id: 7 },
        { img: '/logo/douyu.png', id: 8 },
        { img: '/logo/feizhu.png', id: 9 },
        { img: '/logo/guandiantong.png', id: 10 },
        { img: '/logo/win.png', id: 11 }
      ],
      imgList_2: [
        { img: '/logo/huawei.png', id: 1 },
        { img: '/logo/jimu.webp', id: 2 },
        { img: '/logo/jingdo.webp', id: 3 },
        { img: '/logo/jishutianqi.png', id: 4 },
        { img: '/logo/kaola.webp', id: 5 },
        { img: '/logo/kuaik.png', id: 6 },
        { img: '/logo/kuaishou.png', id: 7 },
        { img: '/logo/kugou.png', id: 8 },
        { img: '/logo/meituan.png', id: 9 },
        { img: '/logo/meizu.webp', id: 10 },
        { img: '/logo/xiaomi.png', id: 11 },
        { img: '/logo/yundo.png', id: 12 }
      ],
      imgList_3: [
        { img: '/logo/onoa.webp', id: 1 },
        { img: '/logo/oppo.webp', id: 2 },
        { img: '/logo/qichezhijia.png', id: 3 },
        { img: '/logo/qminkeige.webp', id: 4 },
        { img: '/logo/ryi.png', id: 5 },
        { img: '/logo/taobao.webp', id: 6 },
        { img: '/logo/tantan.webp', id: 7 },
        { img: '/logo/taqu.png', id: 8 },
        { img: '/logo/texuship.png', id: 9 },
        { img: '/logo/tian.png', id: 10 },
        { img: '/logo/yaoshi.png', id: 11 }
      ],
      imgList_4: [
        { img: '/logo/tianmao.webp', id: 1 },
        { img: '/logo/tianqi.png', id: 2 },
        { img: '/logo/tianyc.webp', id: 3 },
        { img: '/logo/tianyi.png', id: 4 },
        { img: '/logo/toutiao.png', id: 5 },
        { img: '/logo/UC.jpg', id: 6 },
        { img: '/logo/vivo.webp', id: 7 },
        { img: '/logo/wanq.png', id: 8 },
        { img: '/logo/weibo.webp', id: 9 },
        { img: '/logo/weipinghui.png', id: 10 },
        { img: '/logo/yi.png', id: 11 },
        { img: '/logo/zhihu.webp', id: 12 }
      ],
      numbers: 0
    }
  },
  watch: {
    scrollTopNumber(newVal, oldVal) {
      this.numbers = newVal
      //   console.log('滚动条位置', this.numbers)
      let img1 = document.getElementById("imgS1")
      let img2 = document.getElementById("imgS2")
      let img3 = document.getElementById("imgS3")
      let img4 = document.getElementById("imgS4")
      if (this.numbers >= this.partnerId && this.widthNumber > 768) {
        // console.log('到了2884位置')
        this.addImgLeft(img1)
        this.addImgLeft(img2)
        this.addImgLeft(img3)
        this.addImgLeft(img4)
      }
      else {
        img1.style.animationPlayState = 'paused'
        img2.style.animationPlayState = 'paused'
        img3.style.animationPlayState = 'paused'
        img4.style.animationPlayState = 'paused'
      }
    }
  },
  methods: {
    addImgLeft(name) {
      if (name) {
        // name.style.animationName = 'test'
        name.style.animationDuration = '1.5s'
        name.style.animationTimingFunction = 'ease-out'
        name.style.animationIterationCount = 1
        name.style.animationPlayState = 'running'
      }
    },
  }
}
</script>

<style lang="less" scoped>
.conten {
  width: 100%;
  // max-width: 1920px;
  height: 500px;
  // height: 500px;
  background: skyblue;
  background-image: url("../assets/friend.png");
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .imgList {
    width: 68%;
    // width: 1300px;
    // max-width: 1300px;
    height: 1000px;
    // max-height: 1000px;
    margin-top: 70px;
    .imgList_01 {
      width: 100%;
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 70px;
        height: 70px;
        border-radius: 8px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }
    }
    .img_01 {
      animation-name: imgLeft;
    }
    .img_02 {
      animation-name: imgRight;
    }
    .img_03 {
      animation-name: imgLeft;
    }
    .img_04 {
      animation-name: imgRight;
    }
  }
}
@keyframes imgLeft {
  0% {
    opacity: 0;
    transform: translateX(-86vh);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes imgRight {
  0% {
    opacity: 0;
    transform: translateX(86vh);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 768px) {
  .conten {
    width: 100%;
    height: auto;
    margin-top: 0.8rem;
    h1 {
      font-size: 0.4rem;
    }
    .imgList {
      width: 90%;
      height: auto;
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;

      justify-content: space-around;
      .imgList_01 {
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;

        flex-wrap: nowrap;
        .item {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 0.08rem;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 0.08rem;
          }
        }
      }
    }
  }
}
</style>